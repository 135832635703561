
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import SearchResult from "@/components/SearchResult.vue";
import {SwaggerHubApi} from "@/swaggerhub";
import {Getter} from "vuex-class";

/**
 * Search
 *
 * v-text-field with some default styling and behaviour applied.
 * Based upon @nswdoe/doe-ui-core (Search).
 *
 *
 */
@Component({
  components: {
    SearchResult,
  }
})
export default class Search extends Vue {
  public search: string | null = null;
  public debounceTimeout?: number;

  // Two-way bound model of the current selection
  public value: SwaggerHubApi | null = null;

  @Getter('searchResults') public readonly items!: SwaggerHubApi[];
  @Getter('searchError') public readonly error!: string | null;
  @Getter('searchLoading') public readonly loading!: boolean;

  @Prop({ default: "Find or Browse API's", type: String }) public placeholder!: string;

  @Watch('search')
  async searchDebounced(val: string | null): Promise<void> {
    if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      this.performSearch(val);
    }, 300);
  }

  async performSearch(query: string | null): Promise<void> {
    if (query === null) return;
    await this.$store.dispatch('searchSpecs', { owner: 'nsw-education', query });
  }

  @Watch('value')
  async onApiSelected(val: SwaggerHubApi | null): Promise<void> {
    // Assume that the user does not know what they want and doesnt want to leave current page
    if (val === null) return;
    await this.$router.push(`/api/${val.name}/latest`);
  }
}
