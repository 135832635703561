
import { Vue, Component, VModel } from 'vue-property-decorator';
import { SwaggerHubApi, SwaggerHubApiUrlProperty } from '@/swaggerhub';
import NavigationDrawerItem from '@/components/NavigationDrawerItem.vue';
import NavigationDrawer from '@/components/NavigationDrawer.vue';
import SearchResult from './SearchResult.vue';
// import AppBar from "@/components/AppBar.vue";
import Search from './Search.vue';
import { AppBar, AppIcon, Profile } from '@nswdoe/doe-ui-core';
import { Getter, State } from 'vuex-class';
import { useProfile } from '@/plugins/oauth';

@Component({
  components: {
    AppBar,
    AppIcon,
    Search,
    SearchResult,
    NavigationDrawer,
    NavigationDrawerItem,
    Profile,
  },
})
export default class APINavigationMenu extends Vue {
  @VModel({ type: Boolean, default: true }) drawerOpen!: boolean;

  @Getter('listingLoading') readonly loading!: boolean;
  @State('listingError') readonly error!: Error;
  @Getter('specs') readonly items: SwaggerHubApi[];

  swaggerProperty(api: SwaggerHubApi): SwaggerHubApiUrlProperty | undefined {
    return api.properties.find((p) => p.type == 'Swagger');
  }

  apiRoute(api: SwaggerHubApi): string | undefined {
    // const swaggerPath = this.swaggerPath(api);
    // if (!swaggerPath) return undefined;
    // return `/api/${swaggerPath}`;
    return `/api/${api.name}/latest`;
  }

  swaggerPath(api: SwaggerHubApi): string | undefined {
    const swaggerProperty = this.swaggerProperty(api);
    if (!swaggerProperty) return undefined;

    let path = swaggerProperty.url;
    if (
      swaggerProperty.url.indexOf(process.env.VUE_APP_SWAGGERHUB_PREFIX) !== -1
    ) {
      path = swaggerProperty.url.replace(
        process.env.VUE_APP_SWAGGERHUB_PREFIX,
        ''
      );
    }

    return path;
  }

  mounted(): void {
    // this.$store.dispatch('listSpecs');
  }

  logout() {
    this.$OAuth.logout();
  }

  get profile() {
    const { givenName, familyName } = useProfile(this.$OAuth);
    return { givenName, familyName };
  }
}
