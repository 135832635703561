
import {Vue, Component, Prop} from 'vue-property-decorator';


@Component({
  components: {},
})
export default class NavigationDrawerItem extends Vue {
  @Prop({ type: String }) readonly icon!: string;
  @Prop({ type: Boolean, default: false, }) readonly iconOutlined!: boolean;
  @Prop({ type: Number }) readonly notificationCount!: number;
  @Prop({ type: String, required: true }) readonly title!: string;
  @Prop({ type: String }) readonly route!: string;
}
