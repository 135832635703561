// https://ads.education.nsw.gov.au/styles

export enum PrimaryColours {
    Navy = "#041E42",
    Red = "#CE0037",
    LightBlue = "#C8DCF0",
    White = "#FFFFFF",
}

export enum SecondaryColours {
    Blue1 = "#1D428A",
    Blue2 = "#407EC9",
    Blue3 = "#6CACE4",
    Red2 = "#EE3C48",
    Red3 = "#F3B8B5",
}

export enum NotificationColours {
    InfoBlue = "#2E5299",
    SuccessGreen = "#00A908",
    WarningOrange = "#DC5800",
    ErrorRed = "#B81237",
}

export enum MonoGreyColours {
    Dark = "#121212", // Text
    Dark80 = "#333333",
    Dark70 = "#4C4F55",
    Dark60 = "#6D7079", // Low Emphasis Icons
    Light40 = "#A0A5AE", // Dividers
    Light20 = "#E4E4E6",
    Light10 = "#F4F4F7", // Background Colour
    White = "#FFFFFF",
}

// Not in the style guide, but present in @nswdoe/doe-ui-core
export enum UserInterfaceColours {
    Black = "#151515",
    DarkGray = "#2E2F30",
    CoolGrey = "#a9b1b4",
    Grey60 = "#666666",
    Grey30 = "#B3B3B3",
    Grey15 = "#D9D9D9",
    Grey10 = "#E5E5E5",
    Grey5 = "#F5F5F5",
    Grey2 = "#FAFAFA"
}



export enum ComplementaryColours {
    Purple = "#752F8A",
    Orange = "#FF7F2F",
    Yellow = "#F9BE00",
    Green = "#69B32D"
}



