import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { setupOAuth } from './plugins/oauth';
import VueFetch from 'vue-fetch';
import SvgSprite from 'vue-svg-sprite';

// import {IeBlocker} from '@nswdoe/doe-ui-core';

// Config the App root instance (oAuth plugin, etc.)
Vue.config.productionTip = false;
Vue.prototype.$eventHub = new Vue();

Vue.use(VueFetch);
Vue.use(SvgSprite);

setupOAuth(router);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
