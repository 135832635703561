import Vue from "vue";
import Vuex, {StoreOptions} from "vuex";

import swaggerhub from "@/store/modules/swaggerhub";

Vue.use(Vuex);

export interface RootState {
  placeholder: boolean;
}

const rootStoreOptions: StoreOptions<RootState> = {
  modules: {
    swaggerhub,
  },
  state: {
    placeholder: true,
  },
  getters: {},
  mutations: {},
  actions: {}
};

export default new Vuex.Store(rootStoreOptions);

