import {
  ApiError,
  getApiVersions,
  getOwnerApis,
} from "@/api/swagger-hub-registry-api";
import { useOAuth } from "@/plugins/oauth";
import { RootState } from "@/store";
import {
  SwaggerHubApi,
  SwaggerHubSpecsQueryParams,
  SwaggerHubSpecsResponse,
} from "@/swaggerhub";
import { OpenAPIV3 } from "openapi-types";
import { Module } from "vuex";

export interface ApiVersions {
  [apiName: string]: SwaggerHubApi;
}

export interface State {
  listing: SwaggerHubSpecsResponse | null;
  listingLoading: boolean;
  listingError: ApiError | null;
  currentSpecification: OpenAPIV3.Document | null;
  currentSpecificationUrl: string | null;
  currentSpecificationLoading: boolean;
  currentSpecificationError: Error | null;
  currentSummary: SwaggerHubApi | null;
  searchResults: string[];
  searchLoading: boolean;
  searchError: Error | null;
  versionsLoading: boolean;
  versionsError: Error | null;
  versions: ApiVersions;
}

export enum Mutations {
  ListingLoading = "ListingLoading",
  ListingResponse = "ListingResponse",
  ListingError = "ListingError",
  SpecLoading = "SpecLoading",
  SpecResponse = "SpecResponse",
  SpecError = "SpecError",
  SpecReset = "SpecReset",
  VersionsLoading = "VersionsLoading",
  VersionsResponse = "VersionsResponse",
  VersionsError = "VersionsError",
  SearchLoading = "SearchLoading",
  SearchResponse = "SearchResponse",
  SearchError = "SearchError",
}

const { createHeaders } = useOAuth();

const moduleOptions: Module<State, RootState> = {
  state: {
    listing: null,
    listingLoading: false,
    listingError: null,
    currentSpecification: null,
    currentSpecificationUrl: null,
    currentSpecificationLoading: false,
    currentSpecificationError: null,
    currentSummary: null,
    searchLoading: false,
    searchError: null,
    searchResults: [],
    versionsLoading: false,
    versionsError: null,
    versions: {},
  },
  getters: {
    specs: (state) =>
      state.listing && state.listing.apis ? state.listing.apis : null,
    listingLoading: (state) => state.listingLoading,
    currentSummary: (state) => {
      return state.currentSummary;
      // if (!state.currentSpecificationUrl || !state.listing) return null;
      // const currentSpecificationUrlRegexp = new RegExp(`/${state.currentSpecificationUrl}/`);
      //
      // const summary = state.listing.apis.find((api) => {
      //   const swaggerProperty = api.properties.find((p => p.type == "Swagger"));
      //   if (swaggerProperty === null) return false;
      //   return currentSpecificationUrlRegexp.test(swaggerProperty.url);
      // });
      //
      // console.log(summary);
      // return summary;
    },
    currentSpecification: (state) => state.currentSpecification,
    currentSpecificationLoading: (state) => state.currentSpecificationLoading,
    currentSpecificationError: (state) => state.currentSpecificationError,
    searchResults: (state) => state.searchResults,
    searchLoading: (state) => state.searchLoading,
  },
  mutations: {
    [Mutations.ListingLoading](state, loading = true) {
      state.listingLoading = loading;
    },
    [Mutations.ListingResponse](state, response: SwaggerHubSpecsResponse) {
      state.listingLoading = false;
      state.listingError = null;
      state.listing = response;
    },
    [Mutations.ListingError](state, error) {
      state.listingLoading = false;
      state.listingError = error;
    },
    [Mutations.SpecLoading](state, { loading, url, api }) {
      state.currentSpecificationLoading = loading;
      state.currentSpecificationUrl = url;
      if (api) {
        state.currentSummary = api;
      }
    },
    [Mutations.SpecResponse](state, openapi: OpenAPIV3.Document) {
      state.currentSpecificationLoading = false;
      state.currentSpecification = openapi;
    },
    [Mutations.SpecError](state, error) {
      state.currentSpecificationLoading = false;
      state.currentSpecificationError = error;
    },
    [Mutations.SpecReset](state) {
      state.currentSpecification = null;
      state.currentSpecificationUrl = null;
    },
    [Mutations.VersionsLoading](state) {
      state.versionsLoading = true;
    },
    [Mutations.VersionsError](state, error) {
      state.versionsError = error;
    },
    [Mutations.SearchLoading](state) {
      state.searchLoading = true;
    },
    [Mutations.SearchResponse](state, response) {
      state.searchResults = response.apis;
      state.searchLoading = false;
    },
    [Mutations.SearchError](state, error) {
      state.searchLoading = false;
      state.searchError = error;
    },
  },
  actions: {
    async listSpecs({ commit }, query: SwaggerHubSpecsQueryParams) {
      try {
        commit(Mutations.ListingLoading);
        const response = await getOwnerApis(query);
        commit(Mutations.ListingError, null);
        commit(Mutations.ListingResponse, response);
      } catch (e) {
        commit(Mutations.ListingError, e);
      }
    },

    async searchSpecs({ commit }, query: SwaggerHubSpecsQueryParams) {
      try {
        commit(Mutations.SearchLoading);
        const response = await getOwnerApis(query);
        commit(Mutations.SearchResponse, response);
      } catch (e) {
        commit(Mutations.SearchError, e);
      }
    },

    async loadSpec(
      { commit },
      openapi_url: string
    ): Promise<OpenAPIV3.Document> {
      try {
        commit(Mutations.SpecReset);
        commit(Mutations.SpecLoading, { url: openapi_url, loading: true });
        const response = await fetch(openapi_url);
        if (response.status !== 200) {
          throw new Error(
            "Failed to load the API specification, Non-2xx status code"
          );
        }
        const data = await response.json();
        commit(Mutations.SpecResponse, data);

        return data;
      } catch (e) {
        commit(Mutations.SpecError, e);
      }
    },

    async loadSpecByName(
      { commit, state, dispatch },
      name: string
    ): Promise<OpenAPIV3.Document> {
      try {
        commit(Mutations.SpecReset);

        // ICCP-438: direct link to api url: We are attempting to load a specification before we have the listing
        if (!state.listing) {
          await dispatch("listSpecs", {});
        }

        const api = state.listing.apis.find((api) => api.name === name);
        if (!api) {
          commit(
            Mutations.SpecError,
            new TypeError(
              "Invalid API name specified, could not find that API to load."
            )
          );
          return;
        }

        const swaggerProperty = api.properties.find((p) => p.type == "Swagger");
        if (swaggerProperty === null) {
          commit(
            Mutations.SpecError,
            new TypeError(
              "API was found, but it had no swagger url specification so it could not be loaded."
            )
          );
          return;
        }

        commit(Mutations.SpecLoading, {
          url: swaggerProperty.url,
          api,
          loading: true,
        });
        const response = await fetch(swaggerProperty.url, {
          headers: createHeaders(),
        });
        if (response.status !== 200) {
          throw new Error(
            "Failed to load the API specification, Non-2xx status code"
          );
        }
        const data = await response.json();
        commit(Mutations.SpecResponse, data);

        return data;
      } catch (e) {
        commit(Mutations.SpecError, e);
      }
    },

    async loadVersions({ commit }, name: string): Promise<void> {
      try {
        commit(Mutations.VersionsLoading, { name });
        const response = await getApiVersions({ api: name });
        commit(Mutations.VersionsResponse, response);
      } catch (e) {
        commit(Mutations.VersionsError, e);
      }
    },
  },
};

export default moduleOptions;
