
import { AppIcon, BlockUI, ErrorPage } from "@nswdoe/doe-ui-core";
import "reflect-metadata";

import { ApiError } from "@/api/swagger-hub-registry-api";
import Navigation from "@/components/Navigation.vue";
import { SwaggerHubApi } from "@/swaggerhub";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, State } from "vuex-class";

@Component({
  components: {
    BlockUI,
    Navigation,
    AppIcon,
    ErrorPage,
  },
})
export class App extends Vue {
  @Getter("specs") readonly specs: SwaggerHubApi[];
  @Action("listSpecs") listSpecs: () => void;

  @State((state) => state.swaggerhub.listingError) readonly error!: ApiError;

  showNav(): boolean {
    return !this.isUnauthorised;
  }

  get isUnauthorised() {
    return this.error?.isUnauthorised() && this.$OAuth.loaded;
  }

  @Watch("$OAuth.loaded")
  onLoaded() {
    if (this.$OAuth.loaded) {
      this.listSpecs();
    }
  }
}

export default App;
