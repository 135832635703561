import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {NotificationColours, PrimaryColours, SecondaryColours} from '@/colours-light';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: PrimaryColours.Navy,
        secondary: PrimaryColours.LightBlue,
        accent: SecondaryColours.Blue1,
        success: SecondaryColours.Blue2,
        info: NotificationColours.InfoBlue,
        warning: NotificationColours.WarningOrange,
        error: NotificationColours.ErrorRed,
      },
      dark: {
        primary: SecondaryColours.Blue2,
      }
    }
  }
});
