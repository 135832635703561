
import {Vue, Component, Prop} from 'vue-property-decorator';
import {SwaggerHubApi} from "@/swaggerhub";

@Component
export default class SearchResult extends Vue {
  @Prop({ type: Object }) public readonly item!: SwaggerHubApi;

  public get version(): string {
    const versionProperty = this.item.properties.find((p => p.type == "X-Version"));
    if (!versionProperty) return "";
    return versionProperty.value;
  }
}
