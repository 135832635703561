import { AdsOAuth, OAuth, OAuthOptions } from "@nswdoe/doe-ui-core";
import Vue from "vue";
import { VueRouter } from "vue-router/types/router";

const useOAuth = (): {
  createHeaders: () => Headers;
  isAuthenticated: () => boolean;
} => {
  const createHeaders = () => {
    const adsOAuth = Vue.prototype.$OAuth as AdsOAuth;
    const oauthHeaders = adsOAuth.buildHttpHeader();
    return new Headers(oauthHeaders);
  };

  const isAuthenticated = () => {
    const adsOAuth = Vue.prototype.$OAuth as AdsOAuth;
    return adsOAuth.loaded;
  };

  return { createHeaders, isAuthenticated };
};

const useProfile = (oauth: AdsOAuth) => {
  const profile = oauth.profile || {};
  const givenName: string = profile.given_name || profile.name;
  const familyName: string = profile.family_name || " ";
  return { givenName, familyName };
};

const setupOAuth = (router: VueRouter) => {
  const clientId = process.env.VUE_APP_OAUTH_CLIENT_ID;
  const options: OAuthOptions = {
    router: router,
    authoriseUri: process.env.VUE_APP_OAUTH_AUTHORIZE_URL,
    tokenUri: process.env.VUE_APP_OAUTH_TOKEN_URL,
    logoutUri: process.env.VUE_APP_OAUTH_LOGOUT_URL,
    secureApp: true,
    pkce: true,
    forceProdMode: true,
    params: {
      client_id: clientId,
      redirect_uri: process.env.VUE_APP_OAUTH_REDIRECT_URL,
      scope: encodeURIComponent(
        `openid profile email api://${clientId}//EISCAT`
      ),
    },
  };

  Vue.use(OAuth, options);
};

export { setupOAuth, useOAuth, useProfile };
