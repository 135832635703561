
import {Banner} from '@nswdoe/doe-ui-core';
import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: { Banner },
})
export default class Home extends Vue {

  mounted(): void {
    /// https://jira.education.nsw.gov.au/browse/ICCP-436
    // Home must reload list when navigated to.
    // this.$store.dispatch('listSpecs');
  }
}

