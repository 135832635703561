import { useOAuth } from "@/plugins/oauth";
import {
  SwaggerHubSpecSort,
  SwaggerHubSpecsQueryParams,
  SwaggerHubSpecsResponse,
  SwaggerHubSpecType,
  SwaggerHubSpecVisibility,
  SwaggerHubVersionsQueryParams,
  SwaggerHubVersionsResponse,
} from "@/swaggerhub";

export class ApiError extends Error {
  status: number;
  constructor(message: string, status: number) {
    super(message);
    this.status = status;
    Object.setPrototypeOf(this, ApiError.prototype);
  }

  isUnauthorised() {
    return this.status == 401 || this.status == 403;
  }
}

const { createHeaders } = useOAuth();

export const getOwnerApis = async (
  params: SwaggerHubSpecsQueryParams
): Promise<SwaggerHubSpecsResponse> => {
  const defaults: SwaggerHubSpecsQueryParams = {
    owner: process.env.VUE_APP_SWAGGERHUB_OWNER || "nsw-education",
    specType: SwaggerHubSpecType.API,
    visibility: SwaggerHubSpecVisibility.PUBLIC,
    // limit: 40, // empty is no limit
    sort: SwaggerHubSpecSort.TITLE,
  };

  const mergedParams: SwaggerHubSpecsQueryParams = {
    ...defaults,
    ...params,
  };

  const pairs = [];
  for (const prop in mergedParams) {
    if (!Object.hasOwnProperty.call(mergedParams, prop)) continue;
    pairs.push(`${prop}=${mergedParams[prop]}`);
  }
  const queryStr = pairs.join("&");
  const url = `${process.env.VUE_APP_SWAGGERHUB_BASE_URL}/specs?${queryStr}`;
  const response = await fetch(url, { headers: createHeaders() });
  if (response.status !== 200) {
    throw new ApiError("Unable to fetch specs", response.status);
  } else {
    const data = await response.json();
    // TODO: some type guarding here
    return data as SwaggerHubSpecsResponse;
  }
};

export const getApiVersions = async (
  params: SwaggerHubVersionsQueryParams
): Promise<SwaggerHubVersionsResponse> => {
  const defaults: Partial<SwaggerHubVersionsQueryParams> = {
    owner: process.env.VUE_APP_SWAGGERHUB_OWNER || "nsw-education",
  };

  const mergedParams: SwaggerHubVersionsQueryParams = {
    ...defaults,
    ...params,
  };

  const url = `${process.env.VUE_APP_SWAGGERHUB_BASE_URL}/apis/${mergedParams.owner}/${mergedParams.api}`;
  const response = await fetch(url, { headers: createHeaders() });

  if (response.status !== 200) {
    throw new ApiError("Unable to fetch specs", response.status);
  } else {
    const data = await response.json();
    // TODO: some type guarding here
    return data as SwaggerHubVersionsResponse;
  }
};
