
export interface SwaggerHubApiProperty {
    type: string;
    value: string;
}

export interface SwaggerHubApiUrlProperty {
    type: string;
    url: string;
}

export interface SwaggerHubApi {
    name: string;
    description: string;
    tags: string[];
    properties: Array<SwaggerHubApiProperty & SwaggerHubApiUrlProperty>;
}

export interface SwaggerHubAPIListing {
    name: string;
    description: string;
    url: string;
    offset: number;
    totalCount: number;
    apis: SwaggerHubApi[];
}


export interface SwaggerHubSpecsResponse {
    name: string;
    description: string;
    url: "specs";
    offset: number;
    totalCount: number;
    apis: SwaggerHubApi[];
}

// I cant see any differences right now.
export type SwaggerHubVersionsResponse = SwaggerHubSpecsResponse;

export enum SwaggerHubExtensionProperties {
    Domain = "X-Domain",
    Version = "X-Version",
    Created = "X-Created",
    Modified = "X-Modified",
    Published = "X-Published",
    Versions = "X-Versions",
    UserRole = "X-UserRole",
    Private = "X-Private",
    OASVersion = "X-OASVersion",
    Notifications = "X-Notifications",
    CreatedBy = "X-CreatedBy",
}

export enum SwaggerHubSpecType {
    API = "API",
    DOMAIN = "DOMAIN",
    ANY = "ANY",
}

export enum SwaggerHubSpecVisibility {
    PUBLIC = "PUBLIC",
    PRIVATE = "PRIVATE",
    ANY = "ANY"
}

export enum SwaggerHubSpecState {
    UNPUBLISHED = "UNPUBLISHED",
    PUBLISHED = "PUBLISHED",
    ANY = "ANY",
}

export enum SwaggerHubSpecSort {
  NAME = "NAME",
  UPDATED = "UPDATED",
  CREATED = "CREATED",
  OWNER = "OWNER",
  BEST_MATCH = "BEST_MATCH",
  TITLE = "TITLE",
}

export enum SwaggerHubSortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export interface SwaggerHubSpecsQueryParams {
    specType?: SwaggerHubSpecType;
    visibility?: SwaggerHubSpecVisibility;
    state?: SwaggerHubSpecState;
    owner: string;
    query?: string;
    page?: number;
    limit?: number;
    sort?: string;
    order?: "ASC" | "DESC";

    // yes, we can index by string
    [k: string]: string | number | undefined;
}

export interface SwaggerHubVersionsQueryParams {
  owner?: string;
  api: string;
}


export const versionProperty = (api: SwaggerHubApi): SwaggerHubApiProperty | undefined =>
  api.properties.find((p) => p.type === SwaggerHubExtensionProperties.Version);

export const lastModifiedProperty = (api: SwaggerHubApi): SwaggerHubApiProperty | undefined =>
  api.properties.find((p) => p.type === SwaggerHubExtensionProperties.Modified);
